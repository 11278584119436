import { Component, inject, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from './core/auth/auth.store';
import { SocketNotificationsService } from 'app/core/socket-notifications/socket-notifications.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Settings } from 'luxon';
import { JhiAlertComponent } from 'app/shared/alert/alert.component';

@Component({
    standalone: true,
    selector: 'jhi-root',
    imports: [RouterOutlet, JhiAlertComponent],
    template: `
        <router-outlet></router-outlet>
        <jhi-alert />
    `
})
export class AppComponent {
    private authStore = inject(AuthStore);
    private renderer = inject(Renderer2);
    private document = inject(DOCUMENT);
    private translateService = inject(TranslateService);
    private primeNGConfig = inject(PrimeNGConfig);

    constructor() {
        this.authStore.setInitialState();
        this.authStore.checkDeviceId(inject(SocketNotificationsService).deviceId$);
        this.authStore.getAccount(this.authStore.select(s => s.token?.tenant_id));
        this.updateTranslationAndAttributeLang();
        this.updatePrimeNGTranslations();
    }

    updateTranslationAndAttributeLang() {
        this.authStore.langKey$.subscribe(lang => {
            Settings.defaultLocale = lang;
            this.renderer.setAttribute(this.document.querySelector('html'), 'lang', lang);
            this.translateService.use(lang);
        });
    }

    updatePrimeNGTranslations() {
        this.translateService.stream('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    }
}
