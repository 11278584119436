import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAccount } from '../user/account.model';
import { environment } from 'environment';
import { Credentials, RegionTokens, SdsoInfo, TenantTokenDTO, TenantTokenVM } from './auth.model';
import { createParams } from 'app/shared/util/request-util';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private http = inject(HttpClient);
    private registrationUrl = `${environment.entryPoint}/regions/registration`;

    login(credentials: Credentials): Observable<RegionTokens[]> {
        return this.http.post<RegionTokens[]>(`${environment.entryPoint}/regions/oauth2/token`, credentials);
    }

    sdso(sdsoInfo: SdsoInfo): Observable<void> {
        const params = createParams(sdsoInfo);
        return this.http.post<void>(`${environment.notificationService}api/sdso/handle`, null, { params });
    }

    getAccount(): Observable<UserAccount> {
        return this.http.post<UserAccount>(environment.projectAdminService + 'api/user/account', null);
    }

    refreshToken(token: TenantTokenVM): Observable<TenantTokenDTO[]> {
        return this.http.post<TenantTokenDTO[]>(`${token.base_uri}/auth/oauth2/token`, {
            grant_type: 'REFRESH_TOKEN',
            refresh_token: token.refresh_token
        });
    }

    finishRegistration(account: any): Observable<any> {
        return this.http.post(`${this.registrationUrl}/finish`, account);
    }

    resetPassword(reset_key: string, password: string): Observable<any> {
        return this.http.post(`${this.registrationUrl}/forgot-password/confirm`, { reset_key, password });
    }

    sendResetMail(email: string): Observable<any> {
        return this.http.post(`${this.registrationUrl}/forgot-password/send-email`, { email });
    }

    changePassword(new_password: string, current_password: string): Observable<any> {
        return this.http.post('regions/registration/change-password', { current_password, new_password });
    }
}
